<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <h1>EVALUATIONS LIST</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Evaluation' })"
            >Main</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="8" sm="6">
        <div>
          <p>REP: Dave Zwcickel</p>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="$router.push({ name: 'AdjustEvaluation' })"
            >Adj Evals</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="pa-2 table-header" style="background-color: #ff2164">
          PAST DUE
        </div>
        <datatable
          :headers="headers"
          :staticData="items"
          :disableHeader="true"
          @row-clicked="evaluationDetail"
          :componentURL="componentURL"
        ></datatable>

        <div class="mt-8 pa-2 table-header">Today - Monday 9/1/20</div>
        <datatable
          :headers="headers"
          :staticData="items"
          :disableHeader="true"
          @row-clicked="evaluationDetail"
          :componentURL="componentURL"
        ></datatable>

        <div class="mt-8 pa-2 table-header">Tomorrow - Tuesday 9/2/20</div>
        <datatable
          :headers="headers"
          :staticData="items"
          :disableHeader="true"
          @row-clicked="evaluationDetail"
          :componentURL="componentURL"
        ></datatable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";

export default {
  components: { Datatable },
  data() {
    return {
      componentURL: "EvaluationDetail/",
      headers: [
        { text: "date", value: "date" },
        { text: "TIme", value: "time" },
        { text: "name", value: "name" },
        { text: "address", value: "address" },
      ],
      items: [
        {
          date: "11/3/2020",
          time: "12:45 PM",
          id: "1",
          name: "Gerald Form",
          address: "Kansas City, MO",
        },
        {
          date: "11/3/2020",
          time: "12:45 PM",
          id: "2",
          name: "Gerald Form",
          address: "Kansas City, MO",
        },
        {
          date: "11/3/2020",
          time: "12:45 PM",
          id: "3",
          name: "Gerald Form",
          address: "Kansas City, MO",
        },
        {
          date: "11/3/2020",
          time: "12:45 PM",
          id: "4",
          name: "Gerald Form",
          address: "Kansas City, MO",
        },
      ],
      "table-data": [[{ Header: "PAST DUE\t\t\t" }]],
    };
  },
  methods: {
    evaluationDetail(EvaluationDetail) {
      this.$router.push({
        name: "EvaluationDetail",
        params: { id: EvaluationDetail.id },
      });
    },
  },
};
</script>

<style>
.table-header {
  background-color: #7e8594;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
</style>
